import React, { Component } from 'react'

class SymbolIcon extends Component {
    render() {
        const { tipo, urlIcon } = this.props
        return(
            <div className={`symbol symbol-35 symbol-light-${tipo} mr-4 flex-shrink-0`}>
                <div className="symbol-label">
                    <i className={`${urlIcon} text-${tipo}`}></i>
                </div>
            </div>
        )
    }
}

export default SymbolIcon