import React, { Component } from 'react'
import Navbar from 'react-bootstrap/Navbar' 
import SVG from "react-inlinesvg";
import { MailNotification } from '../../../assets';
import { MAIN_FRONT } from '../../../constants';

const NameUser = React.forwardRef(({userName}, ref) => (        
    <div >    
        {userName}      
    </div>
)); 

class CustomUser extends Component{

    logout = () => {
        window.location.href = `${MAIN_FRONT}/login`
    }
    
    render(){
		const { authUser: { user: { name: userName } } } = this.props
        return(       
			<div className="d-flex flex-column">
				<div className="font-weight-bold font-size-h5 text-dark-75 text-justify "><Navbar as={NameUser} userName={userName}> </Navbar></div>
                <div className="navi mt-1"> 
                        <span className="navi-link p-0 pb-1">
                            <span className="navi-icon mr-1">
                                <span className="svg-icon svg-icon-lg svg-icon-primary">
                                    <SVG src = { MailNotification } /> 
                                </span>
                            </span>
                            <a rel="noopener noreferrer" href={"mailto:"+this.props.authUser.user.email} target="_blank">
                                <span className="navi-text text-muted text-hover-primary  ">{this.props.authUser.user.email}</span>
                            </a>
                        </span> 
                    <span className="btn btn-sm btn-light-primary font-weight-bolder mt-2 " onClick = { this.logout } >Cerrar Sesión</span>
                </div>
            </div>
        )
    }
}

export default CustomUser