import React, { Component } from 'react';
import SVG from "react-inlinesvg";
import { Construir, Tarea, Lead, Cuestionario, Portapapeles, Ticket, Vacaciones, Solicitud, Cancel, Money } from '../../assets/svg/notification'
import { AlertSound } from '../../assets/sounds';
class Notificacion extends Component {

    state = {
        audio: ''
    }

    componentDidMount(){
        let { audio } = this.state
        audio = new Audio( AlertSound );
        audio.load();
        audio.loop = true;
        audio.play();
        this.setState({...this.state, audio: audio})
    }

    componentWillUnmount(){
        let { audio } = this.state
        audio.pause();
        this.setState({...this.state, audio: ''})
    }

    setIcon = tipo => {
        switch(tipo){
            case 'lead':
                return Lead
            case 'tarea':
                return Tarea
            case 'ticket':
                return Ticket
            case 'vacaciones':
                return Vacaciones
            case 'solicitud vacaciones':
                return Solicitud
            case 'cuestionario':
                return Cuestionario
            case 'cancel':
                return Cancel
            case 'proyecto':
                return Construir
            case 'money':
                return Money
            default:
                return Portapapeles
        }
    }

    render() {
        const { data } = this.props
        return (
            <div className="row mx-0 align-items-center">
                <div className="col-2 d-flex justify-content-center align-items-center">
                    <span className="svg-icon svg-icon-lg svg-icon-success mx-2">
                        <SVG className = 'w-100' src = { this.setIcon(data.tipo) } />
                    </span>
                </div>
                <div className="col-10"> <div className="text-left text-white text-bold font-bold"> { data.texto } </div> </div>
            </div>
        );
    }
}

export default Notificacion;