import React, { Component } from 'react'
import { Calendar } from 'react-date-range'
import es from "date-fns/locale/es";
export default class CalendarDay extends Component {
    updateDate = item => {
        const { onChange, name } = this.props
        onChange({ target: { name: name, value: item } })
    }
    render() {
        const { date, withformgroup, disabledWeekends, className, requirevalidation, onChange, disabledDates, title, messageinc, ...props } = this.props
        return (
            <>
                <div className="d-flex justify-content-center bg-light py-2 rounded-top">
                    <label className="text-center font-weight-bold color-label-calendar mb-0">{title}</label>
                </div>
                <Calendar className={className} onChange={(item) => { this.updateDate(item) }} locale={es}
                    date={date && date !== '' ? date : null}
                    color="#2171c1"
                    showPreview={false}
                    disabledDay={
                        (date) => {
                            if (disabledWeekends) {
                                let newDate = new Date(date);
                                newDate = newDate.getDay()
                                if (newDate === 0 || newDate === 6)
                                    return true
                                return false
                            } return false
                        }
                    }
                    showDateDisplay={false}
                    {...props} />
                {
                    requirevalidation ? <span className={`form-text text-danger ${date?'d-none':'is-invalid'}`}>{messageinc?messageinc:'Incorrecto. Selecciona la fecha.'}</span> : <></>
                }
            </>
        )
    }
}
