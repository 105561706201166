import React, { Component } from 'react'
import SVG from "react-inlinesvg"
import { AdministracionIcon, BancosIcon, CalidadIcon, CatalogosIcon, InicioIcon, LeadsIcon, MercadotecniaIcon, NormasIcon, PlataformaIcon, PresupuestoIcon, 
    ProyectoIcon, RecursosHumanosIcon, ReportesIcon, SubmoduloIcon, UsuariosIcon } from '../../assets';
import { toAbsoluteUrl } from "../../functions/routers"
import { LEADS_FRONT, MAIN_FRONT } from '../../constants';
class AsideMenuList extends Component {

    openSubmenu = modulo => {
        let aux = document.getElementById(`submenu-${modulo}`);
        let x = document.getElementsByClassName("submenu-asidemenu");
        let cont = 0;
        for(cont = 0; cont < x.length; cont ++){
            if(x[cont] !== aux){
                x[cont].classList.remove('d-block')
            }else{
                if (!aux.classList.contains('d-block')) {
                    x[cont].classList.add('d-block')
                }else{
                    x[cont].classList.remove('d-block')
                }       
            }
        }
    }

    getIcon = (icon) => {
        switch(icon){
            case '/images/svg/inicio.svg':
                return InicioIcon;
            case '/images/svg/usuarios.svg':
                return UsuariosIcon;
            case '/images/svg/presupuesto.svg':
                return PresupuestoIcon;
            case '/images/svg/proyectos.svg':
                return ProyectoIcon;
            case '/images/svg/administracion.svg':
                return AdministracionIcon;
            case '/images/svg/bancos.svg':
                return BancosIcon;
            case '/images/svg/recursoshumanos.svg':
                return RecursosHumanosIcon;
            case '/images/svg/leads.svg':
                return LeadsIcon;
            case '/images/svg/mercadotecnia.svg':
                return MercadotecniaIcon;
            case '/images/svg/normas.svg':
                return NormasIcon;
            case '/images/svg/reportes.svg':
                return ReportesIcon;
            case '/images/svg/calidad.svg':
                return CalidadIcon;
            case '/images/svg/catalogos.svg':
                return CatalogosIcon;
            case '/images/svg/Notifications1.svg':
                return PlataformaIcon;
            case '/images/svg/submodulo.svg':
                return SubmoduloIcon;
            default: break;
        }
    }

    getUrlFromSub = (sub) => {
        const { name, url } = sub
        switch(name){
            case 'CRM':
                
                return url;
            case 'Encuesta de Satisfacción':
            case 'Mi Proyecto':
            case 'Te escuchamos':
            case 'Incidencias':
            case 'Directorio':
            case 'Comunicacion Interna':
            case 'Encuesta graficas':
            case 'Autorizar solicitudes':
            case 'Encuesta':

                return `${LEADS_FRONT}${url}`
                
            default:
                return `${MAIN_FRONT}${url}`
        }
    }
  // return url
    closeAside = () => {
        if(document.body.classList.contains('aside-minimize-hover') ){
            document.body.classList.remove('aside-minimize-hover');       
            document.body.classList.add('aside-minimize');
        }
    }

    refreshCrm = () => {
        const { access_token } = this.props.props.authUser
         window.location.href = `${LEADS_FRONT}/leads/crm?tag=${access_token}`
    }

    render() {
        const modulos = this.props.props.authUser.modulos ? this.props.props.authUser.modulos : [] 
        return (
            <ul className = 'menu-nav'>
                {
                        
                    modulos.map( (modulo, key) => {
                        // console.log(modulo )
                        return(
                            <li className = 'menu-item menu-item-submenu' key = { key } data-menu-toggle = "hover"
                                onClick = { () => { this.openSubmenu(modulo.slug) } }>
                                <div className="menu-link menu-toggle" to = { modulo.url } >
                                    <span className="svg-icon menu-icon">
                                        <SVG src = { this.getIcon(modulo.icon) }/>
                                    </span>
                                    {
                                        modulo.url ? 
                                        modulo.url === '/mi-proyecto' || modulo.url === '/te-escuchamos' || modulo.url === '/directorio' || modulo.url === '/comunicados'|| modulo.url === '/reporte' ||  
                                        modulo.url === '/vacaciones-permisos' ||  modulo.url === '/encuestas/clima-laboral' ? 
                                        <span className="menu-text menu-link text-uppercase" to={`${LEADS_FRONT}${modulo.url}`}>{modulo.name}</span>
                                    :                                    
                                        ''
                                    : 
                                            <span className="menu-text menu-link text-uppercase">{modulo.name}</span>
                                    }
                                    {
                                        modulo.modulos.length > 1 || modulo.url === null  ? 
                                            <i className="menu-arrow"  onClick = { () => { this.openSubmenu(modulo.slug) } }/>
                                        : ''
                                    }
                                </div>
                                {
                                    modulo.modulos.length > 0 || modulo.url !== null ?
                                        <div className="menu-submenu submenu-asidemenu" id = {`submenu-${modulo.slug}`}>
                                            <i className="menu-arrow"  />
                                            <ul className="menu-subnav">
                                                <li className="menu-item  menu-item-parent">
                                                    <span className="menu-link">
                                                        <span className="menu-text">{modulo.name}</span>
                                                    </span>
                                                </li>
                                                {
                                                    modulo.modulos.map( (submodulo) => {
                                                        let url = this.getUrlFromSub(submodulo)
                                                        if(url.includes('https://') || url.includes('http://')){
                                                            return(
                                                                <li  key={submodulo.url} className='menu-item'>
                                                                    <a className="menu-link" href = { url } >
                                                                        <span className="svg-icon menu-icon">
                                                                            <SVG src={toAbsoluteUrl(submodulo.icon)} />
                                                                        </span>
                                                                        <span className="menu-text text-uppercase">{submodulo.name}</span>
                                                                    </a>
                                                                </li>
                                                            )
                                                        }else{
                                                            return(
                                                                <li key = { submodulo.url } className = 'menu-item'>
                                                                    <span className = "menu-link" onClick = { this.refreshCrm } >
                                                                        <span className="svg-icon menu-icon">
                                                                            <SVG src={toAbsoluteUrl(submodulo.icon)} />
                                                                        </span>
                                                                        <span className="menu-text text-uppercase">{submodulo.name}</span>
                                                                    </span>
                                                                </li>
                                                            )
                                                        }
                                                        
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    : ''
                                }
                            </li>
                        )
                    })
                }
            </ul>
        );
    }
}
export default AsideMenuList