import React, { Component } from 'react';
import { CustomUser, NavUser } from '..';
import SVG from "react-inlinesvg";
import { setDateTableLG } from '../../../functions/setters';
import { ImageDefault } from '../../../assets';

class UserPanel extends Component {

	state ={
		showProfile: true,
		notificaciones: [],
	}

    mostrarPerfil() {
        const { showProfile } = this.state
        this.setState({ ...this.state, showProfile: !showProfile })
    }

    closeButton(){
        if(document.getElementsByClassName("offcanvas")[0].classList.contains("offcanvas-on")){
            document.getElementsByClassName("offcanvas")[0].classList.remove("offcanvas-on");
        } else{
            document.getElementsByClassName("offcanvas")[0].classList.add("offcanvas-on");
        }
        this.setState({ ...this.state, showProfile: true })
    }
	
	render(){		
		const { avatar, user, cerrarSesiones, checador, actualizarChecador } = this.props
		const { showProfile, notificaciones } = this.state
		return (
			<div className='offcanvas offcanvas-right px-10 pt-0 border bg-red'>
				<div className='offcanvas-header d-flex align-items-center justify-content-between pt-10 pb-5 notify-p-stiky'>
					<div className="font-weight-bolder m-0 font-size-h3">{showProfile ? 'Perfil de usuario' : 'Notificaciones'}</div>
					<div>
						<button className={`btn btn-xs btn-icon btn-light btn-hover-primary mr-2 ${!showProfile ? '' : 'd-none'}`} 
							onClick={ () => {this.mostrarPerfil()}} >
							<i className="flaticon2-left-arrow-1 icon-sm text-muted"></i>
						</button>
						<button className="btn btn-xs btn-icon btn-light btn-hover-danger" onClick = { () => { this.closeButton() } } >
							<i className="flaticon2-delete icon-xs text-muted"></i>
						</button>
					</div>
				</div>			
				<div className={`offcanvas-content pr-3 mr-n3 ${showProfile ? '' : 'd-none'}`}>
					<div className="d-flex align-items-center mt-3">
						<div className="symbol symbol-100 mr-3">
							<img className="symbol-label bg-transparent"  src={avatar ? avatar : ImageDefault } alt = '' />
						</div>
						<CustomUser {...this.props}/>
					</div>
					<div className="separator separator-dashed mt-4 mb-3"></div>
					<NavUser user = { user } cerrarSesiones = { cerrarSesiones } checador = { checador } actualizarChecador = { actualizarChecador } 
						mostrarNotificaciones={ () => { this.mostrarPerfil() }}/>
					{/*<div className="separator separator-dashed mt-4 mb-4"></div>
					<UserNotifications/>*/}	
				</div>
				<div className={`offcanvas-content pr-3 mr-n3 ${!showProfile ? '' : 'd-none'}`}>
					<div className="mt-3">
						{
							notificaciones.map((notificacion, key) => {
								return(
									<div className="d-flex align-items-start mb-5" key={key}>
										<div className="symbol symbol-50 symbol-light mr-4">
											<span className="symbol-label bg-gray-200">
												<span className="svg-icon svg-icon-lg svg-icon-success mx-2">
													<SVG src = { this.setIcon(notificacion.tipo) } />
												</span>
											</span>
										</div>
										<div>
											<span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">{notificacion.texto}</span>
											<div className="text-muted font-weight-bold d-block">{setDateTableLG(notificacion.created_at)}</div>
										</div>
									</div>
								)
							})
						}
						
					</div>
				</div>
			</div>
		)
	}
}
export default UserPanel