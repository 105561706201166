import React, { useState, useEffect }from "react";
import { useSelector } from "react-redux";

import Layout from "../../components/layout/Layout";
import TablaGeneral from '../../components/NewTables/TablaGeneral/TablaGeneral'

import Swal from 'sweetalert2';

export default function Requisiciones() {
    const userAuth = useSelector((state) => state.authUser.access_token);

    let prop = {
        pathname: '/leads/reporte',
    }

    const columnas = [
        { nombre: 'Nombre', identificador: 'nombre', sort: true, stringSearch: true },
        { nombre: 'Fecha', identificador: 'fecha', sort: true, stringSearch: true },
        { nombre: 'Descripcion', identificador: 'descripcion', sort: true, stringSearch: true },
        { nombre: 'Monto', identificador: 'monto', sort: true, stringSearch: true },
    ]

  return (
    <div>
        <Layout authUser={userAuth} location={prop} history={{ location: prop }} active='leads'>
              <TablaGeneral
                titulo="Historial de minutas" columnas={columnas} url="rh/juntas"
              />
        </Layout>
              
    </div>
  );
}