
import moment from 'moment'
import Moment from 'react-moment'

function compare( a, b ) {
    if ( a.name < b.name ){
        return -1;
    }
    if ( a.name > b.name ){
        return 1;
    }
    return 0;
}
export function setEmpresaLogo(lead) {
    if(lead)
        if(lead.empresa)
            if(lead.empresa.logo_principal)
                if(lead.empresa.logo_principal.length)
                    return lead.empresa.logo_principal[0].url
    return ''
}
export function dayDMY (fecha){
    let fecha_moment = moment(fecha);
    let format = fecha_moment.locale('es').format("DD MMM YYYY");
    return format.replace('.', '');
}
export function setArrayTable(arreglo, minwidth) {
    return (
        <div style={{minWidth:minwidth}}>
            {
                arreglo.map((element, id) => {
                    return (
                        <div key = { id } className={`mb-2 ${minwidth?'':'center-td'}`}>
                            {
                                element.name ?
                                    <span className="mr-1 font-size-11px" >
                                        <span className="font-weight-bold">
                                            {
                                                element.lista ?
                                                    element.name + '.'
                                                : element.name + ':'
                                            }
                                        </span>
                                    </span>
                                    : ''
                            }
                            {
                                element.url ?
                                    <a href={element.url} target="_blank" rel="noopener noreferrer">
                                        <span className="font-size-11px">
                                            {
                                                element.text
                                            }
                                        </span>
                                    </a>
                                    :
                                    <span className="font-size-11px">
                                        {
                                            element.text
                                        }
                                    </span>
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export function setOptionsWithLabel(arreglo, name, value) {
    let aux = setOptions(arreglo, name, value)
    aux.forEach((elemento) => {
        elemento.label = elemento.name
    })
    return aux
}

export function setTextTable(text, minwidth) {
    return (
        <div className="font-size-11px" style={{minWidth:minwidth}}>
            {text}
        </div>
    )
}

export function setContactoIcon(contacto){
    if(contacto)
        if(contacto.tipo_contacto)
            if(contacto.tipo_contacto.tipo){
                switch(contacto.tipo_contacto.tipo){
                    case 'Llamada':
                        return(<i className={contacto.success ? "fas fa-phone-volume text-success icon-16px" : "fas fa-phone-volume text-danger icon-16px"} />);
                    case 'Correo':
                        return(<i className={contacto.success ? "fas fa-envelope text-success icon-16px" : "fas fa-envelope text-danger icon-16px"} />);
                    case 'VIDEO LLAMADA':
                        return(<i className={contacto.success ? "fas fa-video text-success icon-16px" : "fas fa-video text-danger icon-16px"} />);
                    case 'Whatsapp':
                        return(<i className={contacto.success ? "socicon-whatsapp text-success icon-16px" : "socicon-whatsapp text-danger icon-16px"} />);
                    case 'TAWK TO ADS':
                        return(<i className={contacto.success ? "fas fa-dove text-success icon-16px" : "fas fa-dove text-danger icon-16px"} />);
                    case 'REUNIÓN PRESENCIAL':
                        return(<i className={contacto.success ? "fas fa-users text-success icon-16px" : "fas fa-users text-danger icon-16px"} />);
                    case 'Visita':
                        return(<i className={contacto.success ? "fas fa-house-user text-success icon-16px" : "fas fa-house-user text-danger icon-16px"} />);
                    case 'TAWK TO ORGANICO':
                        return(<i className={contacto.success ? "fas fa-dove text-success icon-16px" : "fas fa-dove text-danger icon-16px"} />);
                    default:
                        return(<i className={contacto.success ? "fas fa-mail-bulk text-success icon-16px" : "fas fa-mail-bulk text-danger icon-16px"} />);
                }
            }
    return ''
}
export function setMoneyText(money){
    return '$'+money.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}
export function setDate(date) {
    return (
        <Moment format="DD/MM/YYYY">
            {date}
        </Moment>
    )
}
export function setOptions(arreglo, name, value) {
    let aux = []
    arreglo.map((element) => {
        if (element.hasOwnProperty('cuentas')) {
            aux.push({ name: element[name], value: element[value].toString(), cuentas: element['cuentas'] })
        } else {
            if (element.hasOwnProperty('subareas')) {
                aux.push({ name: element[name], value: element[value].toString(), subareas: element['subareas'] })
            } else {
                if(element.hasOwnProperty('tipos')) {
                    aux.push({ name: element[name], value: element[value].toString(), tipos: element['tipos'] })
                }else{
                    if (element.hasOwnProperty('proyectos')) {
                        if (element.hasOwnProperty('contratos')) {
                            aux.push({ name: element[name], value: element[value].toString(), proyectos: element['proyectos'], contratos: element['contratos'] })
                        } else {
                            aux.push({ name: element[name], value: element[value].toString(), proyectos: element['proyectos'] })
                        }
                    } else {
                        if (element.hasOwnProperty('subpartidas')) {
                            aux.push({ name: element[name], value: element[value].toString(), subpartidas: element['subpartidas'] })
                        } else {
                            if (name==="m2") {
                                aux.push({ name: ""+element[name], value: element[value].toString() })
                            } else {
                                aux.push({ name: element[name], value: element[value].toString() })
                            }
                        }
                    }
                }
            }
        }
        return false
    })

    var hash = {};
    aux = aux.filter(function(current) {
        var exists = !hash[current.value];
            hash[current.value] = true;
        return exists;
    });

    aux.sort(compare)
    return aux
}

export function setDateTableLG(date) {
    let seconds = new Date(date);
    seconds = seconds.getTime() / 1000;
    return (
        <>
            <span className="d-none">
                {
                    seconds
                }
            </span>
            <span className="d-none">
                <Moment format="YYYY/MM/DD">
                    {date}
                </Moment>
            </span>

            <Moment format="DD/MM/YYYY">
                {date}
            </Moment>
        </>
    )
}
export function setNaviIcon(icon, text) {
    return (
        <span className="navi-icon d-flex align-self-center">
            <div className="d-flex"><i className={`${icon} mr-2`} /></div>
            <div className="navi-text align-self-center">
                {text}
            </div>
        </span>
    )
}
export function setEsquema(text) {
    let esquema = ''
    if(text === 'esquema_1'){
        esquema = 'Esquema 1'
    }else if(text === 'esquema_2'){
        esquema = 'Esquema 2'
    }else{
        esquema = 'Esquema 3'
    }
    return esquema
}
export function printTableCP (key, cliente) {
    return (
        <tbody key={key}>
            <tr className="border-top-2px">
                <td className="text-center w-5">
                    <i className="las la-user-alt icon-2x text-dark-50"></i>
                </td>
                <td className="w-33 font-weight-bolder text-dark-50">
                    NOMBRE DE CLIENTE
                </td>
                <td className="font-weight-light">
                    <span>{cliente.name}</span>
                </td>
            </tr>
            <tr>
                <td className="text-center">
                    <i className="las la-map-pin icon-2x text-dark-50"></i>
                </td>
                <td className="font-weight-bolder text-dark-50">
                    CÓDIGO POSTAL
                </td>
                <td className="font-weight-light">
                    <span>{cliente.cp}</span>
                </td>
            </tr>
            <tr>
                <td className="text-center">
                    <i className="las la-globe icon-2x text-dark-50"></i>
                </td>
                <td className="font-weight-bolder text-dark-50">ESTADO</td>
                <td className="font-weight-light">
                    <span>{cliente.estado}</span>
                </td>
            </tr>
            <tr>
                <td className="text-center">
                    <i className="las la-map icon-2x text-dark-50"></i>
                </td>
                <td className="font-weight-bolder text-dark-50">
                    MUNICIPIO/DELEGACIÓN
                </td>
                <td className="font-weight-light">
                    <span>{cliente.municipio}</span>
                </td>
            </tr>
            <tr>
                <td className="text-center">
                    <i className="las la-map-marker icon-2x text-dark-50"></i>
                </td>
                <td className="font-weight-bolder text-dark-50">
                    COLONIA
                </td>
                <td className="font-weight-light text-justify">
                    <span>{cliente.colonia}</span>
                </td>
            </tr>
            <tr>
                <td className="text-center">
                    <i className="las la-map-marked-alt icon-2x text-dark-50"></i>
                </td>
                <td className="font-weight-bolder text-dark-50">
                    CALLE Y NÚMERO
                </td>
                <td className="font-weight-light text-justify">
                    <span>{cliente.calle}</span>
                </td>
            </tr>
        </tbody>
    )
}


export function setTextTableCenter(text, minwidth) {
    return (
        <div className="font-size-11px text-center" style={{minWidth:minwidth}}>
            {text}
        </div>
    )
}

export function setLabelTable(text) {
    return (
        <div className="text-center">
            <span style={{
                display: 'inline-flex',
                padding: '0.5em 0.85em',
                fontSize: '.65rem',
                fontWeight: 600,
                lineHeight:1,
                backgroundColor:`${text.fondo}`,
                color: `${text.letra}`,
                textAlign:'center',
                border: 'transparent',
                whiteSpace:'nowrap',
                verticalAlign:'baseline',
                borderRadius:'0.475rem',
                justifyContent: 'center',
                alignItems: 'center'
            }} >
                {text.estatus}
            </span>
        </div>
    )
  }

export function setFase(proyecto) {
    let aux = ''
    if (proyecto.fase1)
        aux = 'Fase 1'
    if (proyecto.fase2)
        aux = 'Fase 2'
    if (proyecto.fase3)
        aux = 'Fase 3'
    if (proyecto.fase1 && proyecto.fase2)
        aux = 'Fase 1 y 2'
    if (proyecto.fase1 && proyecto.fase3)
        aux = 'Fase 1 y 3'
    if (proyecto.fase2 && proyecto.fase3)
        aux = 'Fase 2 y 3'
    if (proyecto.fase1 && proyecto.fase2 && proyecto.fase3)
        aux = 'Fase 1, 2 y 3'
    return aux
}