const setSingleHeader = (access_token) => {
    if(access_token)
        return{
            Authorization: `Bearer ${access_token}`,
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    else{
        return {
            'Access-Control-Allow-Origin' : '*',
        }
    }
}

const setFormHeader = (access_token) => {
    return{
        Authorization: `Bearer ${access_token}`,
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Content-Type': 'multipart/form-data'
    }

}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export { setSingleHeader, setFormHeader }
