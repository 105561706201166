import React, { Component, Suspense } from 'react'
import { login, logout } from './redux/reducers/auth_user'
import { connect } from 'react-redux';
import { CommonLottie, /*NoAccess,*/ PaperPlane, Error404Page } from './components/Lottie';
import { apiGet, catchErrors } from './functions/api';
import { printResponseErrorAlert } from './functions/alert';
// import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { LEADS_FRONT } from './constants';
import ReporteSatisfaccion from './pages/Satisfaccion/ReporteSatisfaccion';
import Requisiciones from './pages/Requesiciones/Requisiciones'

import Comunicados from "./pages/Comunicados/Comunicados";
import { VacacionesPermisos } from './pages/RecursosHumanos/VacacionesPermisos'

import Permisos from './pages/RecursosHumanos/PermisosVacaciones'
import ClimaLaboral from './pages/RecursosHumanos/Encuestas/ClimaLaboral/ClimaLaboral'

const Crm = React.lazy(() => import('./pages/Crm') )
const LeadLlamadaSalida = React.lazy(() => import('./pages/Add/LeadLlamadaSalida') )
const LeadTelefono = React.lazy(() => import('./pages/Add/LeadTelefono') )
const LeadInfo = React.lazy(() => import('./pages/Info/LeadInfo') )
const LeadContratar = React.lazy(() => import('./pages/Contratar') )
const Miproyecto = React.lazy(() => import('./pages/Miproyecto') )
const TeEscuchamos = React.lazy(() => import('./pages/TeEscuchamos/TeEscuchamos') )
const Satisfaccion = React.lazy(() => import('./pages/Satisfaccion/Satisfaccion') )
const Incidencias = React.lazy(() => import('./pages/Incidencias/Incidencias') )
const Directorio = React.lazy(() => import('./pages/Incidencias/Directorio') )

// const Calculadora = React.lazy(() => import('./pages/calculadora/Inicio') )

// const MiProyecto = React.lazy(() => import('./pages/MiProyecto') )
// const InicioMiProyecto = React.lazy(() => import('./pages/InicioMiProyecto') )

class Page404 extends Component{
    render(){
        return(
            <div className="container">
                <div className = 'd-flex align-items-center' style =  { { height: '100vh' } }>
                    <div className = 'w-50 mx-auto '>
                        <CommonLottie animationData = { Error404Page } />
                    </div>
                </div>
            </div>
        )
    }
}
class App extends Component{
    
    componentDidMount = async() => {
        const { login } = this.props
        let queryString = this.props.history.location.search    
        // console.log(queryString)
        if (queryString) {
            let params = new URLSearchParams(queryString)   
            let tag = params.get('tag')
            let id_lead = parseInt(params.get("id") )    
           
            let estatus_lead = params.get('estatus')
            if(tag){
                apiGet(`v1/user/subdomain/crm`, tag).then((response) => {
                    const { user, modulos } = response.data
                    login({ access_token: tag, user: user, modulos: modulos })
                    let url = window.location.href.split('?tag=')
                    // console.log(url)
                    if(url.length){
                        if(id_lead){
                            console.log(id_lead)
                    // window.location.href = `${LEADS_FRONT}/mi-proyecto?tag=${estatus_lead}`
                            
                            window.location.href = `${LEADS_FRONT}/leads/crm/info/info?lead=${id_lead}&estatus=${estatus_lead}`
                        }
                        else{
                            window.location.href = url[0]
                            // window.location.href = `${LEADS_FRONT}/mi-proyecto`
                        }
                    }
                }, (error) => { printResponseErrorAlert(error) })
                .catch((error) => { catchErrors(error) })
            }
            
        }
    }

    /* componentWillUnmount = () => {
        const { logout } = this.props
        console.log('Un mounting')
        logout()
    } */

    render(){    
        const { access_token } = this.props.authUser
        if(access_token){
            return (
                <div>
                    <Suspense fallback = { 
                        <div className="container">
                            <div className = 'd-flex align-items-center' style =  { { height: '100vh' } }>
                                <div className = 'w-50 mx-auto '>
                                    <CommonLottie animationData = { PaperPlane } />
                                </div>
                            </div>
                        </div>
                        }>
                        <Switch>
                            <Route path = "/leads/crm" exact component = { Crm } />
                            <Route path = "/leads/crm/add/llamada-salida" exact component = { LeadLlamadaSalida } />
                            <Route path = "/leads/crm/add/telefono" exact component = { LeadTelefono } />
                            <Route path = "/leads/crm/info/info" exact component = { LeadInfo } />
                            <Route path = "/leads/crm/contratar" exact component = { LeadContratar } />
                            <Route path = "/leads/reporte" exact component = { ReporteSatisfaccion } />
                            <Route path = "/mi-proyecto" exact component = { Miproyecto } />
                            <Route path = "/te-escuchamos" exact component = { TeEscuchamos } />
                            <Route path = "/satisfaccion" exact component = { Satisfaccion } />
                            <Route path = "/rh/incidencias" exact component = { Incidencias } />
                            <Route path = "/rh/directorio" exact component = { Directorio } />
                            <Route path="/rh/vacaciones-permisos" exact component={ VacacionesPermisos } />
                            <Route path="/rh/permisos-vacaciones" exact component={ Permisos } />
                            <Route path = "/encuesta" exact component = { Satisfaccion } />
                            <Route path="/rh/comunicados" exact component={Comunicados} />
                            <Route path="/rh/encuestas/clima-laboral" exact component={ClimaLaboral} />
                            {/* <Route path = "/calculadora" exact component = { Calculadora } /> */}
                            <Route path="/requisiciones" exact component={ Requisiciones } />
                        
                            <Route path = "*" component = { Page404 } />
                        </Switch>
                    </Suspense>
                </div>
            );
        }else{
            return(
                <div>
                    <Suspense fallback = { 
                        <div className="container">
                            <div className = 'd-flex align-items-center' style =  { { height: '100vh' } }>
                                <div className = 'w-50 mx-auto '>
                                    <CommonLottie animationData = { PaperPlane } />
                                </div>
                            </div>
                        </div>
                        }>
                        <Switch>
                            <Route path = "/encuesta" exact component = { Satisfaccion } />
                            <Route path = "*" component = { Page404 } />
                        </Switch>
                    </Suspense>
                </div>
                // <div className="container">
                //     <Helmet> <title> CRM Administrador de proyectos </title> </Helmet>
                //     <div className = 'd-flex align-items-center' style =  { { height: '100vh' } }>
                //         <div className = 'w-50 mx-auto '>
                //             <CommonLottie animationData = { NoAccess } />
                //         </div>
                //     </div>
                // </div>
            )
        }
        
    }
}

const mapStateToProps = state => { return{ authUser: state.authUser } }
const mapDispatchToProps = dispatch => ({ logout: () => dispatch(logout()), login: payload => dispatch(login(payload)) })

export default connect(mapStateToProps, mapDispatchToProps)(App);
