import axios from 'axios'
import { URL_DEV } from '../constants'
import { setFormHeader, setSingleHeader } from './routers'
import { errorAlert } from './alert'
import store from '../redux/store/store'

export function apiOptions(url, at){
    return axios.options(`${URL_DEV}${url}`, { headers: setSingleHeader(at) })
}
export function apiGet(url, at){
    return axios.get(`${URL_DEV}${url}`, { headers: setSingleHeader(at) })
}
export function apiPutForm(url, form, at){
    return axios.put(`${URL_DEV}${url}`, form, { headers: setSingleHeader(at) })
}
export function apiDelete(url, at){
    return axios.delete(`${URL_DEV}${url}`, { headers: setSingleHeader(at) })
}
export function apiPostForm(url, form, at){
    return axios.post(`${URL_DEV}${url}`, form, { headers: setSingleHeader(at) })
}

export function apiPostFormData(url, data, at){
    return axios.post(`${URL_DEV}${url}`, data, { headers: setFormHeader(at) })
}

export const catchErrors = (error, flag) => {
    let storing = store.getState();
    const { user } = storing.authUser
    if(flag === false){
        errorAlert(`Ocurrió un error desconocido, intenta refrescando la página`)
    }
    console.error(error, 'error')
    if(process.env.NODE_ENV === 'production' || true){
        axios.post(`https://hooks.zapier.com/hooks/catch/6874908/bh2d32o/`,
        encodeForm({
            'error': error,
            'user': user.name,
            'date': new Date(),
            'url': window.location.href
        }), {headers: {'Accept': 'application/json'}}
            ).then(
            (response) => {
                console.log(`RESPONSE: `, response)
            }, (e) => {
                console.log(`Error: `, e)
            }
        )
    }
}

const encodeForm = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
}