export const PROD_LINK = 'https://centromedicovitara.mx/api/'
export const DEV_LINK = 'http://localhost:8000/api/'
export const URL_DEV = process.env.NODE_ENV === 'production' ? PROD_LINK : DEV_LINK

export const MAIN_FRONT = process.env.NODE_ENV === 'production' ? 'https://www.mantenimientoch.com' : 'http://localhost:3000'
export const LEADS_FRONT = process.env.NODE_ENV === 'production' ? 'https://www.matenimientohc.com' : 'http://localhost:3001'

export const URL_ASSETS = process.env.NODE_ENV === 'production' ? 'https://www.matenimientohc.com' : 'http://127.0.0.1:8000'

export const PUSHER_OBJECT = { broadcaster: 'pusher', key: '112ff49dfbf7dccb6934', cluster: 'us2', forceTLS: false }

// eslint-disable-next-line
export const EMAIL = '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
export const TEL = '^[0-9]{10}$'
export const RFC = '[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]?[A-Z,0-9]?[0-9,A-Z]?'

export const SUGERENCIA_COLUMN = [
    { Header: 'Opciones', accessor: 'actions',customRender: true },
    { Header: 'Empleado', accessor: 'empleado', customRender: true },
    { Header: 'Departamento', accessor: 'departamento', customRender: true },
    { Header: 'Estatus', accessor: 'estatus', customRender: true },
]
export const INCAPACIDAD_COLUMNS = [
    { Header: 'OPCIONES', accessor: 'actions', customRender: true,searchable: false  },
    { Header: 'Nombre', accessor: 'nombre', customRender: true },
    { Header: 'Líder inmediato', accessor: 'lider', customRender: true },
    { Header: 'Fechas', accessor: 'fechas', customRender: true },
    { Header: 'Horas', accessor: 'horas', customRender: true },
    { Header: 'Estatus', accessor: 'estatus', customRender: true },
    { Header: 'Descripción del permiso', accessor: 'descripcion', customRender: true },
    { Header: 'Motivo rechazo', accessor: 'rechazo', customRender: true },
]
export const PERMISOS_COLUMNS = [
    // { Header: 'Empleado', accessor: 'empleado' , customRender: false },
    { Header: 'OPCIONES', accessor: 'actions', customRender: true,searchable: false  },
    { Header: 'Nombre', accessor: 'nombre', customRender: true },    
    { Header: 'Líder inmediato', accessor: 'lider', customRender: true },
    { Header: 'Estatus', accessor: 'estatus', customRender: true },
    { Header: 'Descripción del permiso', accessor: 'descripcion', customRender: true },
    { Header: 'Horas', accessor: 'horas', customRender: true },
    { Header: 'Fechas', accessor: 'fechas', customRender: true },
    { Header: 'Motivo rechazo', accessor: 'rechazo', customRender: true },
]

export const DIRECTORIO_COLUMN = [
    { Header: 'Nombre', accessor: 'empleado', customRender: true },
    { Header: 'Correo', accessor: 'correo', customRender: true },
    { Header: 'Telefono Personal', accessor: 'telefono', customRender: true },
    // { Header: 'Nombre de contacto', accessor: 'nocontacto', customRender: true },
    // { Header: 'Telefono Emergencia', accessor: 'telefonocontacto', customRender: true },
    { Header: 'Departamento', accessor: 'departamento', customRender: true },
]
export const TICKETS_ESTATUS = [
    { Header: 'Opciones', accessor: 'actions' },
    { Header: 'Estatus', accessor: 'estatus' },
    { Header: 'Fecha', accessor: 'fecha' },
    { Header: 'Partida', accessor: 'partida' },
    { Header: 'Tipo de trabajo', accessor: 'tipo_trabajo' },
    { Header: 'Descripción', accessor: 'descripcion' },
    { Header: 'Motivo de cancelación', accessor: 'motivo' }
]

export const SOLICITAR_VACACIONES_COLUMNS = [
    { Header: 'opciones', accessor: 'actions', customRender: true },
    { Header: 'nombre', accessor: 'empleado', customRender: true },
    { Header: 'fecha solicitud', accessor: 'fecha_create', customRender: true },
    { Header: 'fecha inicio', accessor: 'fecha_inicio', customRender: true },
    { Header: 'fecha fin', accessor: 'fecha_fin', customRender: true },
    { Header: 'estado', accessor: 'estado', customRender: true },
]

export const SOLICITAR_PERMISOS_COLUMNS = [
    { Header: 'opciones', accessor: 'actions', customRender: true },
    { Header: 'nombre', accessor: 'empleado', customRender: true },
    { Header: 'fecha inicio', accessor: 'fecha_inicio', customRender: true },
    { Header: 'fecha fin', accessor: 'fecha_fin', customRender: true },
    { Header: 'estado', accessor: 'estado', customRender: true },
    { Header : 'comentario', accessor: 'comentario', customRender: true}
]