import React, {useState} from 'react'
import { useSelector } from 'react-redux'

import Swal from 'sweetalert2'

import { apiPostForm } from "../../../functions/api";

import '../../../styles/_comunicados.scss'

export default function SubirComunicado({getinfo, handleClose}){
    const authUser = useSelector(state => state.authUser.access_token)
    const [form, setForm] = useState({
        name: '',
        file: '',
        date: '',
        comunicado: {
            files: [],
            value: '',
            placeholder: 'Adjunto comunicado',
        }
    })
    const [validated, setValidated] = useState(false)

    const hanldelChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const validate = () => {
        let error = true
        if(form.name === ''){
            error = false
        }
        if(form.file === ''){
            error = false
        }
        if(form.date === ''){
            error = false
        }
        setValidated(error)
        return error
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        
        if(validate()){
            let data = new FormData();
            let aux = Object.keys(form)

            aux.forEach((element) => {
                switch (element) {               
                    case 'adjuntos':
                        break;
                    default:
                        data.append(element, form[element])
                        break
                }
                
            })
            data.append(`files_name_comunicado[]`, form.name)
            data.append(`files_comunicado[]`, form.file)
            data.append('adjuntos[]', "comunicado")
            /* let fecha = form.fecha;
            let result = fecha.toISOString();  */
            data.append('fecha', form.date)
            data.append('nombre', form.name)
            // console.log(data.nombre)

            
            try {
                handleClose()
                Swal.fire({
                    title: 'Subiendo comunicado',
                    text: 'Espere por favor',
                    showConfirmButton: false,
                    timer: 1500,
                })
                apiPostForm('comunicado', data, authUser)
                .then(res => {
                    getinfo()
                    Swal.fire({
                        title: 'Correcto',
                        text: 'El comunicado se subió correctamente',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                })
            } catch (error) {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al subir el comunicado',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }
    }

    const handleFile = (e) => {
        setForm({
            ...form,
            file: e.target.files[0]
        })
    }

    return(
        <>
            <div className="container-modal">
                <div className="input-gray">
                    <label htmlFor="name">Nombre</label>
                    <input type="text" id="name" name="name"  value={form.name} onChange={hanldelChange}/>
                </div>
                <div className="input-gray">
                    <label htmlFor="date">Fecha</label>
                    <input type="date" id="date" name="date" value={form.date} onChange={hanldelChange}/>
                </div>
            </div>

            <div>
                <div className="send-comunicado file">

                    <label htmlFor="file">Selecciona el Comunicado</label>
                    <input type="file" id="file" name="file" onChange={handleFile} arial-label="Seleccionar Comunicado" />
                    <div>
                        {form.file.name ? <div className="file-name">{form.file.name}</div> : 'No hay archivo seleccionado'}
                    </div>

                </div>
                <div className="btn-subir">
                    <button onClick={handleSubmit}>Subir</button>
                </div>  
            </div>

            
        </>
        
    )
}